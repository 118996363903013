





































































































.tooltip-content {
  padding: 0.5rem;
  font-size: 1rem;
  text-align: left;

  > div {
    margin-bottom: 0.5rem;
  }

  > div:last-child {
    margin-bottom: 0;
  }
}
